<template>
  <div class="home" v-cloak>
    <van-pull-refresh
      :head-height="100"
      v-model="refreshing"
      @refresh="onRefresh"
      style="padding-top: 1px"
    >
      <van-row>
        <van-col span="18" class="home-header van-ellipsis">{{
          boss.name
        }}</van-col>
        <van-col span="6" class="home-header text-right" @click="sign_out">
          <svg-icon :data_iconName="'signout'" :className="'signout_icon'" />
        </van-col>
      </van-row>
      <div class="home-body">
        <div class="home-body-header">
          <van-row>
            <van-col span="4">
              <van-image class="avatar" round :src="user.avatar" />
            </van-col>
            <van-col span="14">
              <div class="username">{{ user.username }}</div>
              <div class="mobile">{{ user.mobile }}</div>
            </van-col>
            <van-col span="6">
              <div class="sitting" @click="onSettingClick">
                <svg-icon :data_iconName="'edit'" :className="'setting_icon'" />
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="home-body-middle">
          <van-row gutter="20">
            <van-col span="8" @click="onDataClick(1)">
              <div class="number">
                {{ companies_total_count }}({{
                  companies_pending_review_count
                }})
              </div>
              <div class="text">旅行社数</div>
            </van-col>
            <van-col
              span="8"
              class="van-hairline--left van-hairline--right"
              @click="onDataClick(2)"
            >
              <div class="number">
                {{ guides_total_count }}({{ guides_pending_review_count }})
              </div>
              <div class="text">导游数</div>
            </van-col>
            <van-col span="8" @click="onDataClick(3)">
              <div class="number">{{ plans_total_count }}</div>
              <div class="text">行程线路数</div>
            </van-col>
          </van-row>
        </div>
        <div class="home-body-footer">
          <van-row gutter="20">
            <van-col
              span="12"
              v-for="(item, index) in menus"
              :key="index"
              class="home-body-footer-item"
              @click="onMenuClick(item)"
            >
              <van-badge
                dot
                v-if="index == 5 && item.notification_unread_count > 0"
              >
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-badge>
              <van-badge
                dot
                v-else-if="index == 4 && item.emergency_unapproved_count > 0"
              >
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-badge>
              <div v-else>
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { Dialog } from "vant";
import cookies from "../../utils/cookies";
export default {
  name: "home",
  data() {
    return {
      active: "",
      menus: [
        {
          text: "旅行社管理",
          icon: "agency-default",
          active_icon: "agency-active",
          path: "/agency",
        },
        {
          text: "导游管理",
          icon: "guide-default",
          active_icon: "guide-active",
          path: "/guider",
        },
        {
          text: "行程管理",
          icon: "trip-default",
          active_icon: "trip-active",
          path: "/trip",
        },
        {
          text: "出团管理",
          icon: "tuan-default",
          active_icon: "tuan-active",
          path: "/tuan",
        },
        {
          text: "事件管理",
          icon: "message-default",
          active_icon: "message-active",
          path: "/emergencies",
        },
        {
          text: "通知管理",
          icon: "issue-default",
          active_icon: "issue-active",
          path: "/issue/list",
        },
        // {
        //   text: "疫情",
        //   icon: "event",
        //   active_icon: "event-active",
        //   path: "/yiqing",
        // },

        {
          text: "星级管理",
          icon: "event",
          active_icon: "event-active",
          path: "/startManage",
        },

        //  {
        //   text: "评价管理",
        //   icon: "issue-default",
        //   path: "/feedbacks",
        // },
      ],
      homename: cookies.get("name"),
      user: {},
      boss: {},
      companies_total_count: "0",
      guides_total_count: "0",
      plans_total_count: "0",
      companies_pending_review_count: "0",
      guides_pending_review_count: "0",
      refreshing: false,
      loading: true,
    };
  },
  methods: {
    onMenuClick(item) {
      // this.active = index
      // return
      this.$router.push(item.path);
    },
    onSettingClick() {
      this.$router.push("/setting");
    },
    async get_user_info() {
      const res = await this.$apis.get_user_info();
      this.user = res.data.user;
      this.boss = res.data.boss;
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.count_info();
      this.get_user_info();
    },
    async count_info() {
      const res = await this.$apis.count_info();
      this.companies_total_count = res.data.companies_total_count;
      this.guides_total_count = res.data.guides_total_count;
      this.plans_total_count = res.data.plans_total_count;
      this.companies_pending_review_count =
        res.data.companies_pending_review_count;
      this.guides_pending_review_count = res.data.guides_pending_review_count;
      this.loading = false;
      this.refreshing = false;
      this.menus[5].notification_unread_count =
        res.data.notification_unread_count;
      this.menus[4].emergency_unapproved_count =
        res.data.emergency_unapproved_count;
    },
    sign_out() {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定退出吗?",
      })
        .then(() => {
          // on confirm
          _self.$router.push("/login");
        })
        .catch(() => {
          // on cancel
        });
    },
    onDataClick(type) {
      this.$router.push({
        path: "/dataCenter",
        query: {
          type: type,
        },
      });
    },
  },
  created() {},
  activated() {
    this.onRefresh();
    if (!this.user) {
      this.$router.push("/login");
    }
  },
};
</script>
<style scoped>
.home {
  padding: 0.3rem;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 2rem;
}
.home-header {
  color: #fff;
  font-weight: bold;
  font-size: 0.5rem;
  height: 7vh;
  text-shadow: 0 0 2px #000;
}
.home-body {
  width: 100%;
  /* overflow-y: scroll; */
}
.home-body-header {
  padding: 0.2rem 0rem;
  position: relative;
}
.home-body-header .avatar {
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
.home-body-header .username {
  color: #fff;
  font-size: 0.3rem;
  font-weight: bold;
}
.home-body-header .mobile {
  color: #fff;
  font-size: 0.25rem;
  opacity: 0.74;
  margin-top: 4px;
}
.home-body-middle {
  background: #fff;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  padding: 0.3rem;
}
.home-body-middle .number {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
  height: 50%;
}
.home-body-middle .text {
  color: #666;
  font-size: 14px;
  text-align: center;
  height: 50%;
}
.home-body-footer {
  background: #fff;
  margin-top: 2vh;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  /* overflow-y: scroll; */
}
.home-body-footer-item {
  height: 18vh;
  text-align: center;
  padding: 0.3rem;
}
.item-text {
  font-size: 16px;
  color: #666666;
}
.item_icon {
  font-size: 1.4rem;
}
.sitting {
  text-align: right;
}
.setting_icon {
  font-size: 0.8rem;
}
.signout {
  font-size: 14px;
  position: absolute;
  top: 0.2rem;
  right: 0.4rem;
  color: #fff;
}
.text-right {
  text-align: right;
}
.signout_icon {
  font-size: 0.8rem;
}
[v-cloak] {
  display: none;
}
</style>